import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import msg from './assets/js/message'
import myPagination from "./components/pagination"
import mySearch from "./components/search"
import myUpload from './components/upload'
// 引入路由鉴权
import "./permisstion"

Vue.component("my-upload", myUpload)
Vue.component("my-pagination", myPagination)
Vue.component("my-search", mySearch)
Vue.prototype.$msg = msg // 全局挂载
Vue.use(ElementUI)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})