//统一管理咱们项目用户相关的接口
import request from "@/utils/request"
//项目用户相关的请求地址
const API = {
  LOGIN_URL: "/spread/login/password",
  LIST_URL: "/spread/user/list",
  DETAILS_URL: "/spread/user/details",
  PASSWORD_URL: "/spread/reset_password/spread",
}

//登录接口
export const reqLogin = (data) =>
  request.post(API.LOGIN_URL, data)

// 用户列表
export const list = (params) => request.get(API.LIST_URL, { params });

// 用户详情
export const detail = (params) => request.get(API.DETAILS_URL, { params });

//修改密码
export const editPassword = (data) =>
  request.post(API.PASSWORD_URL, data)